import React, { useState, useEffect, memo } from 'react';
import { Tooltip, App } from 'antd';
import { useToggle, useControllableValue } from 'ahooks';
import { StaffSelect } from '@/components';
import { IUserInfo } from '@/api/auth';
import './index.less';

const MAX_SELECT = 30;

interface IProps {
  authEdit?: boolean;
  isMultipleSelect?: boolean;
  secondConfirm?: boolean;
  isDetail?: boolean;
  maxSelect?: number;
  value?: any;
  onChange?: (value?: any) => void;
}

const OkrPrincipal = (props: IProps) => {
  const {
    authEdit = false,
    isMultipleSelect = false,
    secondConfirm = false,
    isDetail = false,
    maxSelect = MAX_SELECT,
  } = props;

  const [state, { toggle }] = useToggle(false);
  const [value, setValue] = useControllableValue(props);
  const [select, setSelect] = useState(props.value);

  const { modal, message } = App.useApp();

  useEffect(() => {
    setSelect(value);
  }, [value]);

  const checkIsSameUser = (originUser: IUserInfo, newUser: IUserInfo) => {
    return +originUser?.id === +newUser?.id;
  };

  const onBlur = () => {
    const isSameUser = checkIsSameUser(value, select);
    if (isSameUser) {
      toggle();
      setSelect(value);
      return;
    }
    if (secondConfirm) {
      modal.confirm({
        title: '修改负责人后，该OKR将会转移给新的负责人，是否继续？',
        onOk: () => {
          toggle();
          setValue(select);
        },
        onCancel: () => {
          toggle();
          setSelect(value);
        },
      });
    } else {
      toggle();
      setValue(select);
    }
  };

  const onClickItem = () => {
    toggle();
  };

  const onChange = (value: any) => {
    if (isMultipleSelect) {
      if (!value.length) {
        return message.info('负责人不能为空！');
      }
      if (value.length > maxSelect) {
        return message.info(`负责人最多不能超过${maxSelect}个！`);
      }
    }
    setSelect(value);
  };

  const isMultiple = Array.isArray(value) && value.length > 1;
  const text = Array.isArray(value) ? (
    isDetail ? (
      <span>{(value || []).map((i) => i.name).join('，')}</span>
    ) : value.length > 1 ? (
      <span>{value[0]?.name || ''}等</span>
    ) : (
      value[0]?.name
    )
  ) : (
    value?.name
  );

  // 编辑权限
  return authEdit ? (
    // 是否编辑状态
    state ? (
      <StaffSelect
        className="m-okr-principal-select"
        mode={isMultipleSelect ? 'multiple' : undefined}
        value={select}
        onChange={onChange}
        autoFocus
        style={{ width: '100%' }}
        onBlur={onBlur}
      />
    ) : (
      <Tooltip title={isMultiple ? value.map((i) => i?.name).join('，') : '修改负责人'}>
        <span className="m-okr-principal m-is-edit" onClick={onClickItem}>
          {text}
        </span>
      </Tooltip>
    )
  ) : // value是否多个人
  isMultiple ? (
    <Tooltip title={value.map((i) => i?.name).join('，')}>
      <span className="m-okr-principal">{text}</span>
    </Tooltip>
  ) : (
    <span className="m-okr-principal">{text}</span>
  );
};

export default memo(OkrPrincipal);
